import { ToggleComponent } from '../../featureToggle';
import Footer from './Footer';
import FooterNuttall from '../../customized/alan-nuttall-flexeserve-eu/components/footer/Footer';
import FooterOFC from '../../customized/ofc-serenity-eu/components/footer/Footer';
import FooterHengel from '../../customized/hengel-eu/components/footer/Footer';
import FooterSC from '../../customized/structural-concepts/components/footer/Footer';

export default ToggleComponent(Footer, {
  StyledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': FooterNuttall,
    'ofc-serenity-eu': FooterOFC,
    'hengel-eu': FooterHengel,
    'structural-concepts': FooterSC,
  },
});
