import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import React from 'react';
import { createTheme } from '@mui/material/styles';
import { componentsOverrides } from '../../../theme/ThemeProviderWithTheme';
import './fontface.scss';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#284C68',
    },
    secondary: {
      // Nav color
      main: '#284C68',
    },
  },
  typography: {
    fontFamily:
      '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"',
  },
  components: {
    ...componentsOverrides,
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#B5C4C7',
          color: '#284C68',
          '&:hover': {
            color: 'white',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily:
            '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"!important',
        },
      },
    },
  },
});

export default function ThemeProviderWithTheme({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
