import withStyles from 'react-jss';

import Footer from '../../../../components/footer/Footer';

const styles = {
  '@global': {
    '.footer-navigation .menu': {
      background: '#284C68!important',
    },
  },
};

export default withStyles(styles)(Footer);
