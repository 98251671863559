import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { Provider, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';
import store from '../../../../redux/Store';
import BrowserUtil from '../../../../util/BrowserUtil';
import ConnectContactUs from '../../../../components/contactUs';
import ConnectFleetManagerInfo from '../../../../components/LandingPage/fleetManagerInfo';
import ConnectRecipesInfo from '../../../../components/LandingPage/recipesInfo';
import { withPolyglot } from '../../../../i18n';
import imageLandingPageDesktop from '../../assets/SC_background.jpg';
import { ReactComponent as ImgLogo } from '../../assets/SC-logo_square_white.svg';
import { Box, Button, IconButton } from '@mui/material';

const browserUtil = new BrowserUtil();
const isPhone = browserUtil.getIsPhone();
const isMobile = browserUtil.getIsMobile();

export class LandingPage extends Component {
  /**
   * Columns grid boxes isMobile
   * in mobile devices tablet and phones by the landscape orientation the boxes should be
   * showed in the same row. by the portrait rotation the boxes will be showed vertically
   */

  static columnsGridBoxesMobile() {
    return browserUtil.getOrientation() === browserUtil.getLandscape() ? 5 : 9;
  }

  constructor(props) {
    super(props);
    this.orientationChange = this.orientationChange.bind(this);
    this.openContactUs = this.openContactUs.bind(this);
    this.closeContactUs = this.closeContactUs.bind(this);
    this.openFleetManagerInfo = this.openFleetManagerInfo.bind(this);
    this.closeFleetManagerInfo = this.closeFleetManagerInfo.bind(this);
    this.openRecipesInfo = this.openRecipesInfo.bind(this);
    this.closeRecipesInfo = this.closeRecipesInfo.bind(this);
    this.selectPicturesGalery = this.selectPicturesGalery.bind(this);
    this.state = {
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
      showContactUs: false,
      showFleetManagerInfo: false,
      showRecipesInfo: false,
    };
  }
  componentDidMount() {
    window.addEventListener('orientationchange', this.orientationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.orientationChange);
  }
  selectPicturesGalery() {
    const imagesGalleryLandingPage = [{ original: imageLandingPageDesktop }];
    return imagesGalleryLandingPage;
  }

  /**
   * Orientation change
   * this function will be called by a orientation change between landscape and Portrait
   * in mobile devices
   */
  orientationChange() {
    this.setState({
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
    });
  }

  openContactUs() {
    this.setState({
      showContactUs: true,
    });
  }

  closeContactUs() {
    this.setState({ showContactUs: false });
  }

  openFleetManagerInfo() {
    const { loggedIn, history } = this.props;
    if (!loggedIn) {
      this.setState({ showFleetManagerInfo: true });
    } else {
      history.push('/devicemanager');
    }
  }

  closeFleetManagerInfo() {
    this.setState({ showFleetManagerInfo: false });
  }

  openRecipesInfo() {
    this.setState({ showRecipesInfo: true });
  }

  closeRecipesInfo() {
    this.setState({ showRecipesInfo: false });
  }

  render() {
    const { polyglot } = this.props;
    const {
      showContactUs,
      showFleetManagerInfo,
      showRecipesInfo,
      imagesGalleryLandingPage,
      columnsGridBoxesMobile,
    } = this.state;
    return (
      /* the id=container is used to get the hight of this element in order to
       * change the position of the footer: absolute or relative
       */
      <Provider store={store}>
        <div className="landing-page">
          <ConnectContactUs
            polyglot={polyglot}
            showContactUs={showContactUs}
            closeContactUs={this.closeContactUs}
          />
          <ConnectFleetManagerInfo
            polyglot={polyglot}
            showFleetManagerInfo={showFleetManagerInfo}
            closeFleetManagerInfo={this.closeFleetManagerInfo}
          />
          <ConnectRecipesInfo
            polyglot={polyglot}
            showRecipesInfo={showRecipesInfo}
            closeRecipesInfo={this.closeRecipesInfo}
          />

          <div className="container padded">
            <div className="background-images">
              <ImageGallery
                items={imagesGalleryLandingPage}
                showThumbnails={false}
                showBullets={false}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay
                slideDuration={4000}
                Id="imageGallery"
              />
            </div>
            <div className="information">
              <Grid centered columns={16} padded>
                <Grid.Column
                  textAlign="center"
                  verticalAlign="middle"
                  computer={4}
                  widescreen={3}
                  largeScreen={3}
                  tablet={12}
                  mobile={12}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.openFleetManagerInfo}
                    onKeyPress={this.openFleetManagerInfo}
                    tabIndex="0"
                    role="link"
                    sx={{
                      padding: 6,
                      borderRadius: '50%',
                      width: '200px',
                      height: '200px',

                      // invert colors
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: (theme) =>
                          theme.palette.primary.contrastText,
                        color: (theme) => theme.palette.primary.dark,
                      },
                    }}
                  >
                    <ImgLogo height={'8em'} />
                  </Button>
                </Grid.Column>
                <Grid.Column
                  textAlign="center"
                  verticalAlign="middle"
                  computer={4}
                  widescreen={3}
                  largeScreen={3}
                  tablet={12}
                  mobile={12}
                >
                  <Button
                    variant="contained"
                    onClick={this.openContactUs}
                    onKeyPress={this.openContactUs}
                    tabIndex="0"
                    role="link"
                    sx={{
                      padding: 6,
                      borderRadius: '50%',
                      width: '200px',
                      height: '200px',
                      margin: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      name="info circle"
                      size={isPhone ? 'large' : 'huge'}
                      className="icon"
                    />
                    <Box padding={1}>
                      {polyglot.t('landing_page.contact_us')}
                    </Box>
                  </Button>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </Provider>
    );
  }
}

LandingPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool,
};

LandingPage.defaultProps = {
  loggedIn: false,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withPolyglot(LandingPage)));
